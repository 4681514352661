import { useStore } from "@nanostores/react";
import { useCallback, type PropsWithChildren, type ReactElement } from "react";
import { $isShareOpen } from "stores/share";
import Dialog from "./ui/Dialog";
import { DialogTitle } from "@headlessui/react";

interface Props extends PropsWithChildren {
  close?: ReactElement;
}

export default function ShareDialog({ children, close }: Props) {
  const open = useStore($isShareOpen);

  const onClose = useCallback(() => {
    $isShareOpen.set(false);
  }, []);

  return (
    <Dialog open={open} onClose={onClose}>
      <div className="bg-white p-6">
        <div className="absolute right-0 top-0 pr-6 pt-6">
          <button
            type="button"
            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
            onClick={onClose}>
            <span className="sr-only">Close</span>
            {close}
          </button>
        </div>
        <div className="sm:flex sm:items-start">
          <div className="text-left w-full">
            <DialogTitle
              as="h3"
              className="text-lg font-semibold leading-6 text-gray-900">
              Share
            </DialogTitle>
            <div className="mt-1 mb-5">{children}</div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
